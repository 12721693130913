.connection-search-table-container {
  overflow-x: auto;
}

.connection-search-booking-table-input-div {
  display: flex;
}

.connection-search-booking-table-input-contact-email {
  margin-right: 5px;
}

.connection-search-table-column-name-row {
  height: 65px;
}

.connection-search-table-parameter-select .menu {
  max-height: 65px !important;
}
