.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nav-link {
  display: inline-block;
}


.logo-fraud {
  background-image: url("assets/projectavatar.png");
  width: 2.25rem;
  height: 2.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 30px;
}

.upload-loading-spinner-container {
  text-align: center;
}

.upload-loading-spinner-container .warning-message {
  color: red;
}
