.booking-detail-table-disabled-link,
.booking-detail-table-disabled-link:hover {
    color: gray;
    cursor: not-allowed;
}

.booking-detail-table-coupon-amount {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #2f323d;
}

.booking-detail-table-column-name-row {
    min-height: 115px;
}

.booking-detail-table-filter-attribute-select .menu {
    max-height: 115px !important;
}
