.cardContainer {
    display: flex;
    align-items: flex-start;
}

.form {
    flex: 0 0 600px;
}

.center {
    text-align: center;
}
